<template>
  <div>Render</div>
</template>

<script>
export default {
  name: "calendar-tooltip-component",
  props: {
    eventRecord: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
