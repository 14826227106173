<template>
  <modal :show.sync="isOpen">
    <div class="w-100 d-flex flex-column align-items-center">
      <span
        class="d-inline-flex justify-content-center align-items-center w-60px h-60px border border-15px border-warning rounded-circle mb-3"
      >
        <i class="fas fa-info text-warning"></i>
      </span>
      <h2 class="h1">{{ $t("SPOTS.OUT_OF_SERVICE_REASON") }}</h2>

      <div class="w-100 form-wrapper width_1 px-2">
        <base-input>
          <el-select v-model="reason">
            <el-option
              v-for="option in outOfServiceReasonOptions"
              :key="option"
              :value="option"
              :label="$t(`BOOKINGS.REASON_${option}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="d-flex w-100">
        <base-button outline class="w-50" @click="isOpen = false">
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button class="w-50" @click="submit" :disabled="!reason">
          {{ $t("COMMON.ADD") }}
        </base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { Select, Option } from "element-ui";

import { outOfServiceReasonOptions } from "../constants/bookings";

export default {
  name: "out-of-service-confirmation-modal",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const isOpen = this.open ?? false;
    return {
      isOpen,
      reason: null,
      outOfServiceReasonOptions,
    };
  },

  methods: {
    submit() {
      if (!this.reason) return;

      this.onConfirm(this.reason);
    },
  },

  watch: {
    open(value) {
      this.isOpen = value;
    },

    isOpen(value) {
      this.reason = null;
      this.$emit("update:open", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.w-60px {
  width: 60px;
}
.h-60px {
  height: 60px;
}
.border-15px {
  border-width: 15px !important;
}
</style>
